.p-home {
    margin-top: rem(24);

    &_panel {
        background: $white-smoke1;
        border-radius: rem(4);
        padding: rem(8);
    }

    &_info {
        &-cardList {
            margin: rem(20) 0;
        }

        &-table {
            margin-bottom: rem(20);
            margin-top: rem(16);
        }

        &-imageList {
            @include adjust-flex(flex-start, center);
            margin: 0 rem(-6);
            flex-wrap: wrap;
        }

        &-imageItem {
            max-width: rem(110);
            width: 100%;
            padding: rem(6);
            overflow: hidden;

            .a-image {
                border-radius: rem(4);
            }
        }
    }

    &_loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($black, 0.1);
        z-index: 10;
        @include adjust-flex();
    }

    &_evaluate {
        &-form {
            margin-top: rem(16);
        }

        &-button {
            margin-top: rem(24);
            margin-bottom: rem(8);
        }
    }

    &_total {
        @include adjust-flex(flex-start, center);

        &-score {
            margin-left: rem(12);
            padding: rem(10) rem(16);
            border: 1px solid $gainsboro;
            border-radius: rem(4);
            width: rem(73);
            text-align: center;
        }
    }

    &_radioGroup {
        margin-top: rem(8);
        @include adjust-flex(flex-start, center);

        &-item {
            &:last-child {
                margin-left: rem(42);
            }
        }
    }

    &_divider {
        width: 100%;
        height: 1px;
        background-color: $anti-flash-white1;
    }

    &_mt {
        margin-top: rem(16);
    }
}
