.t-pageLayout {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: #{$height-header}px;

    &_header {
        flex-shrink: 0;
    }

    &_body {
        padding: 0 rem(16);
        flex-grow: 1;
        overflow-y: auto;

        @include mobile-down {
            padding: 0 rem(8);
        }
    }

    &_footer {
        display: flex;
        align-items: center;
        padding-left: rem(16);
        padding-right: rem(16);
        gap: rem(16);
        height: rem(64);
        flex-shrink: 0;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    }
}
