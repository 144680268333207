.p-selectStaff {
    margin-top: rem(24);

    &_searchList {
        margin-top: rem(22);
        overflow-y: auto;

        &-item {
            padding: rem(10) rem(8);
            border-bottom: 1px solid $anti-flash-white1;
            cursor: pointer;

            &:hover {
                background-color: $anti-flash-white;
            }
        }
    }


    &_button {
        margin-top: rem(24);
        margin-bottom: rem(8);
        width: 100%;
    }

    &_title {
        margin-bottom: rem(8);
    }

    &_panel {
        background: $white-smoke1;
        border-radius: rem(4);
        padding: rem(8);
    }
}
