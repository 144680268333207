.o-ratingStar {
    $root: &;

    &_rate {
        @include adjust-flex(flex-start, flex-end);
        margin: 0 rem(-6);
        @include mobile-down {
            margin: 0 rem(-4);
        }
    }

    &_error {
        margin-top: rem(4);
    }

    &_label {
        @include font-base(14, 20);
        color: $jet;
        display: inline-block;
        font-weight: 500;
        margin-bottom: rem(8);

        &-big {
            @include font-base(16, 24);
            font-weight: 600;
        }

        &-required {
            color: $red-orange;
            margin-left: rem(4);
            @include font-base(16, 24);
        }
    }

    &_subLabel {
        @include font-base(12, 14);
        display: inline-block;
        color: $stormcloud;
        margin-left: rem(4);
        font-weight: 500;
    }


    &_item {
        cursor: pointer;
        padding-left: rem(6);
        padding-right: rem(6);

        @include mobile-down {
            padding-left: rem(4);
            padding-right: rem(4);
        }
    }
}
