.o-header {

    padding: rem(24);
    background-image: $primary-linear;
    @include adjust-flex();
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-header;

    &-secondary {
        background-image: unset;
        background-color: $white-smoke2;
        border-bottom: 1px solid $gainsboro;
    }
}
